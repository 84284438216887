<template>
  <v-btn
    @click="$emit('click')"
    rounded
    ripple
    dark
    color="purple"
    :loading="loading"
  >
    <v-icon class="mr-2">fas fa-history</v-icon>
    {{
      time
        ? `Refreshed ${timeAgo(new Date(parseInt(time)))}`
        : `Not Refreshed Yet`
    }}
  </v-btn>
</template>

<script>
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addLocale(en);
export default {
  name: "global_refresh_button",
  methods: {
    timeAgo(date) {
      const timeAgo = new TimeAgo("en-US");
      return timeAgo.format(date);
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    time: {
      type: [String, Number, Boolean],
      default: false
    }
  }
};
</script>

<style scoped></style>
